<template>
  <div class="yuerin">
      <div class="yuerin-one">
        <el-carousel class="yuerin-one-carousel" :height="screenwidths" direction="vertical" :autoplay="false">
          <el-carousel-item>
            <div class="yuerin-one-carousel-boxs">
              <div class="yuerin-one-carousel-boxs-top">
                <div class="yuerin-one-carousel-boxs-top-left">
                  <div class="yuerin-one-carousel-boxs-top-left-logo1">
                    <i class="iconfont icon-yuerin-logo2"></i>
                  </div>
                  <div class="yuerin-one-carousel-boxs-top-left-logo2">
                    <i class="iconfont icon-yuerin-logo"></i>
                  </div>
                </div>
                <div class="yuerin-one-carousel-boxs-top-right">
                  <div class="yuerin-one-carousel-boxs-top-right-small">
                    <el-dropdown trigger="click">
                      <el-button size="mini" class="btnn" :icon="Search" circle>
                        <el-icon><arrow-down-bold /></el-icon>
                      </el-button>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item @click.native="go('#yuertwo')">{{ i18n.$t('product') }}</el-dropdown-item>
                          <el-dropdown-item @click.native="go('#yuerthree')">{{ i18n.$t('program') }}</el-dropdown-item>
                          <el-dropdown-item @click.native="go('#yuerfive')">{{ i18n.$t('aboutUs') }}</el-dropdown-item>
                          <el-dropdown-item @click.native="dialogVisible=true">{{ i18n.$t('contactUs') }}</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                  <div class="yuerin-one-carousel-boxs-top-right-boxs">
                    <span @click="go('#yuertwo')">{{ i18n.$t('product') }}</span>
                  </div>
                  <div class="yuerin-one-carousel-boxs-top-right-boxs">
                    <span @click="go('#yuerthree')">{{ i18n.$t('program') }}</span>
                  </div>
                  <div class="yuerin-one-carousel-boxs-top-right-boxs">
                    <span @click="go('#yuerfive')">{{ i18n.$t('aboutUs') }}</span>
                  </div>
                  <div class="yuerin-one-carousel-boxs-top-right-boxs">
                    <span @click="dialogVisible=true">{{ i18n.$t('contactUs') }}</span>
                  </div>
                  <div class="yuerin-one-carousel-boxs-top-right-boxs">
                    <el-dropdown trigger="click">
                      <i class="iconfont icon-duoyuyan"></i>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item @click.native="language('cn')">{{ i18n.$t('zh') }}</el-dropdown-item>
                          <el-dropdown-item @click.native="language('en')">{{ i18n.$t('en') }}</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </div>
              </div>
              <div class="yuerin-one-carousel-boxs-bottom">
                <i v-if="lang=='cn'" class="iconfont icon-yueerchuangxin"></i>
                <i v-else  class="iconfont icon-yuerin-logo"></i>
                <span v-if="lang=='cn'">智能硬件解决方案专家！</span>
                <span v-else style="letter-spacing: 2px;">Expert in intelligent hardware solutions!</span>
                <el-button type="primary" class="yuerin-one-carousel-boxs-bottom-btn">{{ i18n.$t('technologies') }}</el-button>
              </div>  
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="yuerin-two" id="yuertwo">
        <div class="yuerin-two-top">
          <span class="yuerin-two-top-ti">
            {{ i18n.$t('startNow') }}
          </span>
          <span class="yuerin-two-top-tx">
            {{ i18n.$t('lightweight') }}
          </span>
        </div>
        <div class="yuerin-two-bottom">
          <el-carousel class="yuerin-two-carousel" :height="productWidths" direction="vertical" :autoplay="false">
              <el-carousel-item>
                <div class="yuerin-two-carousel-item-one">
                  <div class="yuerin-two-carousel-item-top">
                    <div class="yuerin-two-carousel-item-top-btn">
                      <span>{{ i18n.$t('videoTerminal') }}</span>
                    </div>
                  </div>
                  <div class="yuerin-two-carousel-item-bottom">
                    <div class="container">
                      <div class="box">
                          <span style="--i:1"></span>
                          <span style="--i:2"></span>
                          <span style="--i:3"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="yuerin-two-carousel-item-two">
                  <div class="yuerin-two-carousel-item-top">
                    <div class="yuerin-two-carousel-item-top-btn">
                      <span>{{ i18n.$t('stereoMicrophone') }}</span>
                    </div>
                  </div>
                  <div class="yuerin-two-carousel-item-bottom">
                    <div class="container">
                      <div class="box">
                          <span style="--i:1"></span>
                          <span style="--i:2"></span>
                          <span style="--i:3"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="yuerin-two-carousel-item-three">
                  <div class="yuerin-two-carousel-item-top">
                    <div class="yuerin-two-carousel-item-top-btn">
                      <span>{{ i18n.$t('remoteControl') }}</span>
                    </div>
                  </div>
                  <div class="yuerin-two-carousel-item-bottom">
                    <div class="container">
                      <div class="box">
                          <span style="--i:1"></span>
                          <span style="--i:2"></span>
                          <span style="--i:3"></span>
                      </div>
                    </div>
                  </div>
                </div>
                
              </el-carousel-item>
          </el-carousel>
        </div>
        
      </div>
      <div class="yuerin-three" id="yuerthree">
        <div class="yuerin-three-top">
          <span class="yuerin-three-top-ti">
            {{ i18n.$t('painPoints') }}
          </span>
          <span class="yuerin-three-top-tx">
            {{ i18n.$t('authoritative') }}
          </span>
        </div>
        <div class="yuerin-three-bottom">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card class="yuerin-three-bottom-card" shadow="hover">
                <div class="yuerin-three-bottom-card-left">
                  <div class="yuerin-three-bottom-card-left-tb">
                    <i class="iconfont icon-jianrongxingceshi"></i>
                  </div>
                </div>
                <div class="yuerin-three-bottom-card-right">
                  <span class="yuerin-three-bottom-card-right-ti">
                    {{ i18n.$t('fullyCompatible') }}
                  </span>
                  <span class="yuerin-three-bottom-card-right-tx">
                    {{ i18n.$t('compatibility') }}
                  </span>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card class="yuerin-three-bottom-card" shadow="hover">
                <div class="yuerin-three-bottom-card-left">
                  <div class="yuerin-three-bottom-card-left-tb">
                    <i class="iconfont icon-dingzhi"></i>
                  </div>
                </div>
                <div class="yuerin-three-bottom-card-right">
                  <span class="yuerin-three-bottom-card-right-ti">
                    {{ i18n.$t('customization') }}
                  </span>
                  <span class="yuerin-three-bottom-card-right-tx">
                    {{ i18n.$t('support') }}
                  </span>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card class="yuerin-three-bottom-card" shadow="hover">
                <div class="yuerin-three-bottom-card-left">
                  <div class="yuerin-three-bottom-card-left-tb">
                    <i class="iconfont icon-anquan"></i>
                  </div>
                </div>
                <div class="yuerin-three-bottom-card-right">
                  <span class="yuerin-three-bottom-card-right-ti">
                    {{ i18n.$t('reliable') }}
                  </span>
                  <span class="yuerin-three-bottom-card-right-tx">
                    {{ i18n.$t('choice') }}
                  </span>
                </div>
              </el-card>
            </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top:24px">
            <el-col :span="8">
              <el-card class="yuerin-three-bottom-card" shadow="hover">
                <div class="yuerin-three-bottom-card-left">
                  <div class="yuerin-three-bottom-card-left-tb">
                    <i class="iconfont icon-shengtai"></i>
                  </div>
                </div>
                <div class="yuerin-three-bottom-card-right">
                  <span class="yuerin-three-bottom-card-right-ti">
                    {{ i18n.$t('maturity') }}
                  </span>
                  <span class="yuerin-three-bottom-card-right-tx">
                    {{ i18n.$t('chip') }}
                  </span>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card class="yuerin-three-bottom-card" shadow="hover">
                <div class="yuerin-three-bottom-card-left">
                  <div class="yuerin-three-bottom-card-left-tb">
                    <i class="iconfont icon-kefu"></i>
                  </div>
                </div>
                <div class="yuerin-three-bottom-card-right">
                  <span class="yuerin-three-bottom-card-right-ti">
                    {{ i18n.$t('sales') }}
                  </span>
                  <span class="yuerin-three-bottom-card-right-tx">
                    {{ i18n.$t('solve') }}
                  </span>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card class="yuerin-three-bottom-card" shadow="hover">
                <div class="yuerin-three-bottom-card-left">
                  <div class="yuerin-three-bottom-card-left-tb">
                    <i class="iconfont icon-hezuo"></i>
                  </div>
                </div>
                <div class="yuerin-three-bottom-card-right">
                  <span class="yuerin-three-bottom-card-right-ti">
                    {{ i18n.$t('winwin') }}
                  </span>
                  <span class="yuerin-three-bottom-card-right-tx">
                    {{ i18n.$t('cooperation') }}
                  </span>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="yuerin-five" id="yuerfive">
          <el-carousel class="yuerin-five-carousel" :height="bgWidths" direction="vertical" :autoplay="false">
              <el-carousel-item>
                <div class="yuerin-five-carousel-box">
                  <div class="yuerin-five-carousel-left">
                    <span class="yuerin-five-carousel-left-ti">{{ i18n.$t('aboutYuerin') }}</span>
                    <span class="yuerin-five-carousel-left-tx">{{ i18n.$t('smartly') }}</span>
                    <div class="yuerin-five-carousel-left-line"></div>
                    <span class="yuerin-five-carousel-left-te">{{ i18n.$t('yuertx') }}<br>{{ i18n.$t('yuertx2') }}</span>
                  </div>
                  <div class="yuerin-five-carousel-right">
                    <img src="../assets/images/about.png" alt="">
                  </div>
                </div>
              </el-carousel-item>
          </el-carousel>      
      </div>
      <div class="yuerin-six">
        <div class="yuerin-six-top">
          <span class="yuerin-six-top-ti">{{ i18n.$t('trust') }}</span>
          <span class="yuerin-six-top-tx">{{ i18n.$t('together') }}</span>
        </div>
        <div class="yuerin-six-bottom-samll">
            <el-row :gutter="12" style="margin-bottom:12px">
              <el-col :span="12">
                <el-card class="yuerin-six-bottom-card" :body-style="{ padding: '0px' }">
                  <img src="../assets/images/jiazhi.png" class="yuerin-six-bottom-image" />
                  <div class="yuerin-six-bottom-card-boxs">
                    <div class="yuerin-six-bottom-card-boxs-top">
                      <span class="yuerin-six-bottom-card-boxs-top-ti1">{{ i18n.$t('trustz') }} · </span>
                      <span class="yuerin-six-bottom-card-boxs-top-ti2">{{ i18n.$t('yuerin') }}</span>
                    </div>
                    <div class="yuerin-six-bottom-card-boxs-bottom">
                      <span>
                        {{ i18n.$t('attention') }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="yuerin-six-bottom-card" :body-style="{ padding: '0px' }">
                  <img src="../assets/images/jiazhi2.png" class="yuerin-six-bottom-image" />
                  <div class="yuerin-six-bottom-card-boxs">
                    <div class="yuerin-six-bottom-card-boxs-top">
                      <span class="yuerin-six-bottom-card-boxs-top-ti1">{{ i18n.$t('focus') }} · </span>
                      <span class="yuerin-six-bottom-card-boxs-top-ti2">{{ i18n.$t('extreme') }}</span>
                    </div>
                    <div class="yuerin-six-bottom-card-boxs-bottom">
                      <span>
                        {{ i18n.$t('solution1') }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <el-row :gutter="12">
              <el-col :span="12">
                <el-card class="yuerin-six-bottom-card" :body-style="{ padding: '0px' }">
                  <img src="../assets/images/jiazhi3.png" class="yuerin-six-bottom-image" />
                  <div class="yuerin-six-bottom-card-boxs">
                    <div class="yuerin-six-bottom-card-boxs-top">
                      <span class="yuerin-six-bottom-card-boxs-top-ti1">{{ i18n.$t('publicPraise') }} · </span>
                      <span class="yuerin-six-bottom-card-boxs-top-ti2">{{ i18n.$t('fast') }}</span>
                    </div>
                    <div class="yuerin-six-bottom-card-boxs-bottom">
                      <span>
                        {{ i18n.$t('brand') }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="yuerin-six-bottom-card" :body-style="{ padding: '0px' }">
                  <img src="../assets/images/jiazhi4.png" class="yuerin-six-bottom-image" />
                  <div class="yuerin-six-bottom-card-boxs">
                    <div class="yuerin-six-bottom-card-boxs-top">
                      <span class="yuerin-six-bottom-card-boxs-top-ti1">{{ i18n.$t('open') }} · </span>
                      <span class="yuerin-six-bottom-card-boxs-top-ti2">{{ i18n.$t('ecological') }}</span>
                    </div>
                    <div class="yuerin-six-bottom-card-boxs-bottom">
                      <span>
                        {{ i18n.$t('purpose') }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
        </div>
        <div class="yuerin-six-bottom">
            <el-row :gutter="22">
              <el-col :span="6">
                <el-card class="yuerin-six-bottom-card" :body-style="{ padding: '0px' }">
                  <img src="../assets/images/jiazhi.png" class="yuerin-six-bottom-image" />
                  <div class="yuerin-six-bottom-card-boxs">
                    <div class="yuerin-six-bottom-card-boxs-top">
                      <span class="yuerin-six-bottom-card-boxs-top-ti1">{{ i18n.$t('trustz') }} · </span>
                      <span class="yuerin-six-bottom-card-boxs-top-ti2">{{ i18n.$t('yuerin') }}</span>
                    </div>
                    <div class="yuerin-six-bottom-card-boxs-bottom">
                      <span>
                        {{ i18n.$t('attention') }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card class="yuerin-six-bottom-card" :body-style="{ padding: '0px' }">
                  <img src="../assets/images/jiazhi2.png" class="yuerin-six-bottom-image" />
                  <div class="yuerin-six-bottom-card-boxs">
                    <div class="yuerin-six-bottom-card-boxs-top">
                      <span class="yuerin-six-bottom-card-boxs-top-ti1">{{ i18n.$t('focus') }} · </span>
                      <span class="yuerin-six-bottom-card-boxs-top-ti2">{{ i18n.$t('extreme') }}</span>
                    </div>
                    <div class="yuerin-six-bottom-card-boxs-bottom">
                      <span>
                        {{ i18n.$t('solution1') }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card class="yuerin-six-bottom-card" :body-style="{ padding: '0px' }">
                  <img src="../assets/images/jiazhi3.png" class="yuerin-six-bottom-image" />
                  <div class="yuerin-six-bottom-card-boxs">
                    <div class="yuerin-six-bottom-card-boxs-top">
                      <span class="yuerin-six-bottom-card-boxs-top-ti1">{{ i18n.$t('publicPraise') }} · </span>
                      <span class="yuerin-six-bottom-card-boxs-top-ti2">{{ i18n.$t('fast') }}</span>
                    </div>
                    <div class="yuerin-six-bottom-card-boxs-bottom">
                      <span>
                        {{ i18n.$t('brand') }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="6">
                <el-card class="yuerin-six-bottom-card" :body-style="{ padding: '0px' }">
                  <img src="../assets/images/jiazhi4.png" class="yuerin-six-bottom-image" />
                  <div class="yuerin-six-bottom-card-boxs">
                    <div class="yuerin-six-bottom-card-boxs-top">
                      <span class="yuerin-six-bottom-card-boxs-top-ti1">{{ i18n.$t('open') }} · </span>
                      <span class="yuerin-six-bottom-card-boxs-top-ti2">{{ i18n.$t('ecological') }}</span>
                    </div>
                    <div class="yuerin-six-bottom-card-boxs-bottom">
                      <span>
                        {{ i18n.$t('purpose') }}
                      </span>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
        </div>
      </div>
      <div class="yuerin-seven">
        <el-carousel class="yuerin-seven-carousel" :height="bg2Widths" direction="vertical" :autoplay="false">
              <el-carousel-item>
                <div class="yuerin-seven-top">
                  <span class="yuerin-seven-top-ti">{{ i18n.$t('philosophy') }}</span>
                  <span class="yuerin-seven-top-tx">{{ i18n.$t('professional') }}</span>
                </div>
                <div class="yuerin-seven-bottom">
                  <el-row :gutter="22">
                    <el-col :span="12">
                      <el-card class="yuerin-seven-card" shadow="always"> 
                        <div class="yuerin-seven-card-ti">
                          <span>{{ i18n.$t('profession') }}</span>
                        </div>
                        <div class="yuerin-seven-card-tx">
                          <span>
                            {{ i18n.$t('professionalteam') }}
                          </span>
                        </div>
                      </el-card>
                    </el-col>
                    <el-col :span="12">
                      <el-card class="yuerin-seven-card-2" shadow="always"> 
                        <div class="yuerin-seven-card-ti">
                          <span>{{ i18n.$t('trust2') }}</span>
                        </div>
                        <div class="yuerin-seven-card-tx">
                          <span>
                            {{ i18n.$t('direction') }}
                          </span>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row :gutter="22" style="margin-top:20px">
                    <el-col :span="12">
                      <el-card class="yuerin-seven-card-2" shadow="always"> 
                        <div class="yuerin-seven-card-ti">
                          <span>{{ i18n.$t('focus2') }}</span>
                        </div>
                        <div class="yuerin-seven-card-tx">
                          <span>
                            {{ i18n.$t('AVIoT') }}
                          </span>
                        </div>
                      </el-card>
                    </el-col>
                    <el-col :span="12">
                      <el-card class="yuerin-seven-card" shadow="always"> 
                        <div class="yuerin-seven-card-ti">
                          <span>{{ i18n.$t('open2') }}</span>
                        </div>
                        <div class="yuerin-seven-card-tx">
                          <span>
                            {{ i18n.$t('rewards') }}
                          </span>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                </div>
              </el-carousel-item>
        </el-carousel>
      </div>
      <div class="yuerin-eight">
         <el-carousel class="yuerin-eight-carousel" :height="bg3Widths" direction="vertical" :autoplay="false">
            <el-carousel-item>
              <div class="yuerin-eight-top">
                <span>{{ i18n.$t('reputation') }}</span>
              </div>
              <div class="yuerin-eight-bottom">
                <div class="yuerin-eight-bottom-boxs">
                  <span class="yuerin-eight-bottom-boxs-ti">15 </span>
                  <span class="yuerin-eight-bottom-boxs-tx">{{ i18n.$t('year') }}</span>
                  <div>
                    <span>{{ i18n.$t('accumulation') }}</span>
                  </div>
                </div>
                <div class="yuerin-eight-bottom-boxs">
                  <span class="yuerin-eight-bottom-boxs-ti">9 </span>
                  <span class="yuerin-eight-bottom-boxs-tx">{{ i18n.$t('kind') }}</span>
                  <div>
                    <span>{{ i18n.$t('security') }}</span>
                  </div>
                </div>
                <div class="yuerin-eight-bottom-boxs">
                  <span class="yuerin-eight-bottom-boxs-ti">8 </span>
                  <span class="yuerin-eight-bottom-boxs-tx">{{ i18n.$t('kind') }}</span>
                  <div>
                    <span>{{ i18n.$t('scene') }}</span>
                  </div>
                </div>
                <div class="yuerin-eight-bottom-boxs">
                  <span class="yuerin-eight-bottom-boxs-ti">10 </span>
                  <span class="yuerin-eight-bottom-boxs-tx">{{ i18n.$t('indivual') }}</span>
                  <div>
                    <span>{{ i18n.$t('solution') }}</span>
                  </div>
                </div>
              </div>
            </el-carousel-item>
         </el-carousel>
      </div>
      <div class="yuerin-nine">
        <div class="yuerin-nine-top">
          <span class="yuerin-nine-top-ti">{{ i18n.$t('call') }}</span>
          <span class="yuerin-nine-top-tx">{{ i18n.$t('performance') }}</span>
        </div>
        <div class="yuerin-nine-bottom">
          <el-row :gutter="22">
            <el-col :span="12">
              <el-card class="yuerin-nine-bottom-card" shadow="always">
                <div class="yuerin-nine-bottom-card-top">
                  <div class="yuerin-nine-bottom-card-top-left">                    
                    <span>{{ i18n.$t('sale') }}</span>
                  </div>
                  <div class="yuerin-nine-bottom-card-top-right">
                    <i class="iconfont icon-a-shouqianfuwu1"></i>
                  </div>
                </div>
                <div class="yuerin-nine-bottom-card-bottom">
                  <span class="yuerin-nine-bottom-card-bottom-ti">{{ i18n.$t('immediately') }}</span>
                  <span class="yuerin-nine-bottom-card-bottom-tx" @click="dialogVisible=true">{{ i18n.$t('contactUs') }} ></span>
                </div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="yuerin-nine-bottom-card" shadow="always">
                <div class="yuerin-nine-bottom-card-top">
                  <div class="yuerin-nine-bottom-card-top-left">                    
                    <span>{{ i18n.$t('Technical') }}</span>
                  </div>
                  <div class="yuerin-nine-bottom-card-top-right">
                    <i class="iconfont icon-a-weihu1"></i>
                  </div>
                </div>
                <div class="yuerin-nine-bottom-card-bottom">
                  <span class="yuerin-nine-bottom-card-bottom-ti">{{ i18n.$t('questions') }}</span>
                  <span class="yuerin-nine-bottom-card-bottom-tx" @click="dialogVisible=true">{{ i18n.$t('contactUs') }} ></span>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="yuerin-ten">
        <div class="yuerin-ten-box">
          <div class="yuerin-ten-boxs yuerin-ten-boxs-one">
            <div class="yuerin-ten-boxs-logos">
                <div class="yuerin-ten-boxs-lo1">
                  <i class="iconfont icon-yuerin-logo2"></i>
                </div>
                <div class="yuerin-ten-boxs-lo2">
                  <i class="iconfont icon-yuerin-logo"></i>
                </div>
            </div>
            <div class="yuerin-ten-boxs-logos-bo">
              <span>{{ i18n.$t('Empowering') }}</span>
            </div>
          </div>
          <div class="yuerin-ten-boxs">
            <span class="yuerin-ten-boxs-ti">
              {{ i18n.$t('entry') }}
            </span>
            <span>
              {{ i18n.$t('description') }}
            </span>
            <span>
              {{ i18n.$t('solution') }}
            </span>
            <span>
              {{ i18n.$t('service') }}
            </span>
          </div>
          <div class="yuerin-ten-boxs">
            <span class="yuerin-ten-boxs-ti">
              {{ i18n.$t('help') }}
            </span>
            <span>
              {{ i18n.$t('address') }}
            </span>
            <span>
              {{ i18n.$t('phone') }}
            </span>
            <span>
              {{ i18n.$t('email') }}
            </span>
          </div>
          <div class="yuerin-ten-boxs yuerin-ten-boxs-two">
            <span class="yuerin-ten-boxs-ti" @click="dialogVisible=true">
              {{ i18n.$t('contactUs') }}
            </span>
            <span>
              <i class="iconfont icon-a-daohangdizhi1"></i>
              {{ i18n.$t('address') }}：{{ i18n.$t('add') }}
            </span>
            <span>
              <i class="iconfont icon-a-dianhua1"></i>
              {{ i18n.$t('phone') }}：0755-23050016 何信龙13357120005 赵晓明15920078336 察志富18598275899
            </span>
            <span>
              <i class="iconfont icon-a-youxiang1"></i>
              {{ i18n.$t('email') }}：support@yuerin.com
            </span>
          </div>
          <el-divider />
        </div>
        <div class="yuerin-ten-over">
          <span>Copyright © 深圳市悦尔创新科技有限公司 2021-2022 All Rights Reserved <a href="https://beian.miit.gov.cn/">粤ICP备2022043077号</a></span><br>
          <span><img src="../assets/images/gongan.png" style="margin-right: 10px;margin-top: 5px;" /><a style="display: inline-block;vertical-align: top;line-height: 32px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602006761">粤公网安备 44030602006761号</a></span>
        </div>
      </div>
      <el-dialog
        custom-class="dialog-yuerin"
        v-model="dialogVisible"
        title="联系我们"
        width="1030px"
      >
      <el-descriptions
        size="large"
        class="margin-top"
        :column="2"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <user />
              </el-icon>
              {{ i18n.$t('manager') }}
            </div>
          </template>
          赵晓明
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <iphone />
              </el-icon>
              {{ i18n.$t('phone') }}
            </div>
          </template>
          15920078336
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <user />
              </el-icon>
              {{ i18n.$t('Major') }}
            </div>
          </template>
          何信龙
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <iphone />
              </el-icon>
              {{ i18n.$t('phone') }}
            </div>
          </template>
          13357120005
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <user />
              </el-icon>
              {{ i18n.$t('executive') }}
            </div>
          </template>
          察志富
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <iphone />
              </el-icon>
              {{ i18n.$t('phone') }}
            </div>
          </template>
          18598275899
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <Phone />
              </el-icon>
              {{ i18n.$t('phone') }}
            </div>
          </template>
          0755-23050016
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <Message />
              </el-icon>
              {{ i18n.$t('email') }}
            </div>
          </template>
          support@yuerin.com
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon>
                <Location />
              </el-icon>
              {{ i18n.$t('address') }}
            </div>
          </template>
          {{ i18n.$t('add') }}
        </el-descriptions-item>
      </el-descriptions>
      
        <!-- <el-form :model="form" label-width="80px">
          <el-form-item label="您的公司">
            <el-input v-model="form.company" />
          </el-form-item>
          <el-form-item label="联系人">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="form.phone" />
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="form.email" />
          </el-form-item>
          <el-form-item label="备注信息">
            <el-input v-model="form.remark" />
          </el-form-item>
        </el-form> -->
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">确定</el-button>
            <!-- <el-button type="primary" @click="add()" >确定</el-button> -->
          </span>
        </template>
      </el-dialog>
  </div>
</template>
<script>
import axios from "axios"
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { ElMessage } from 'element-plus'
import { useI18n } from "../uilt/i18n";

export default {
  setup() {
      const i18n = useI18n();
      // console.log("这个",i18n);
      const switchLanguage = () => {
        i18n.locale.value = 'en'
        // console.log("这个",i18n.locale);
        //找地方保存一下修改的值
      };
      // function language(type) {
      //   i18n.locale.value = type;
      //   console.log("获取",this.lang);
      // }
      return {
        i18n,
        switchLanguage
      };
    },
  name: "HomeView",
  // components: {
  //   // HelloWorld,
  // },
  
  data(){
    return{
      lang:'cn',
      diaWidth:'60%',
      dialogVisible:false,
      form:{
        name:'',
        phone:'',
        email:'',
        company:'',
        remark:'',
        creatitime:''
      },
      bg3Widths:(document.documentElement.clientWidth * 390) / 1980 + "px",
      bg2Widths:(document.documentElement.clientWidth * 565) / 1980 + "px",
      bgWidths:(document.documentElement.clientWidth * 565) / 1980 + "px",
      productWidths:(document.documentElement.clientWidth * 665) / 1980 + "px",
      screenwidths:(document.documentElement.clientWidth * 660) / 1980 + "px",
    }
  },
  mounted(){
    window.addEventListener('resize',() =>(this.getHeight()))
    //   window.onresize = () => {
    //     return (() => {
    //       if (document.documentElement.clientWidth > 400) {
    //         this.screenwidths = (document.documentElement.clientWidth * 665) / 1980 + "px";
    //         console.log("是啥？",this.screenwidths);
    //       }
    //     });
    // };
    if(document.documentElement.clientWidth < 1270){
      this.bg2Widths='465px'
      this.bg3Widths='260px'
    }
    if(document.documentElement.clientWidth < 820){
      this.bgWidths='228px'
    }
    if(document.documentElement.clientWidth < 550){
      this.screenwidths='180px'
      this.productWidths ='180px'
      this.diaWidth='90%'
    }
  },
  methods:{
    language(type) {
        // const i18nss = useI18n();
        // let aaa=useI18n();
        // this.$i18n.locale = type;
        this.i18n.locale.value=type
        this.lang=type
        // i18n.locale.value = type;
        console.log("获取",this.i18n.locale.value);
      },
    add() {   //添加操作
      if(this.form.name==""){
        ElMessage.error('请输入联系人')
        return;
      }
      if(this.form.phone==""){
        ElMessage.error('请输入电话')
        return;
      }
      if(this.form.email==""){
        ElMessage.error('请输入邮箱')
        return;
      }
      axios.get('http://82.156.56.14:8089/customer/add',{
        params: {
          id: this.uuid2(16, 16),
          name: this.form.name,
          phone:this.form.phone,
          email: this.form.email,
          company: this.form.company,
          remark:this.form.remark,
          creatitime:this.getNowTime(),
        }
      }).then(res=>{
          console.log(res.data);
          this.form={
            name:'',
            phone:'',
            email:'',
            company:'',
            remark:'',
            creatitime:''
          }
          this.dialogVisible=false
          // this.all()
          ElMessage({
            message: '操作成功',
            type: 'success',
          })
      }).catch(err=>{
          console.log("获取数据失败" + err);
      })
    },
    getNowTime() {
        var date = new Date();
        //年 getFullYear()：四位数字返回年份
        var year = date.getFullYear();  //getFullYear()代替getYear()
        //月 getMonth()：0 ~ 11
        var month = date.getMonth() + 1;
        //日 getDate()：(1 ~ 31)
        var day = date.getDate();
        //时 getHours()：(0 ~ 23)
        var hour = date.getHours();
        //分 getMinutes()： (0 ~ 59)
        var minute = date.getMinutes();
        //秒 getSeconds()：(0 ~ 59)
        var second = date.getSeconds();

        var time = year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
        return time;
    },
    addZero(s) {
        return s < 10 ? ('0' + s) : s;
    },
    // 指定长度和基数
    uuid2(len, radix) {
        var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
        var uuid = [],i;
        radix = radix || chars.length;
        if (len) {
            // Compact form
            for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
        } else {
            // rfc4122, version 4 form
            var r;
    
            // rfc4122 requires these characters
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
            uuid[14] = '4';
    
            // Fill in random data.  At i==19 set the high bits of clock sequence as
            // per rfc4122, sec. 4.1.5
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                    r = 0 | Math.random() * 16;
                    uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                }
            }
        }
        return uuid.join('');
    },
    go(item){
      this.isGo=true
      this.$nextTick(() => {
        document.querySelector(item).scrollIntoView({ behavior:"smooth"});
      })
      /*或
      document.querySelector("#idshow").scrollIntoView();
  	  或
  	  this.$refs.view.scrollIntoView();
  	  */
    },
    getHeight(){
      // if (document.documentElement.clientWidth > 400) {
      //   this.productWidths = (document.documentElement.clientWidth * 660) / 1980 + "px";
      // }
      if (document.documentElement.clientWidth > 1270) {
        this.bg3Widths=(document.documentElement.clientWidth * 390) / 1980 + "px",
        this.bg2Widths=(document.documentElement.clientWidth * 565) / 1980 + "px"
      }
      if (document.documentElement.clientWidth > 820) {
        this.bgWidths=(document.documentElement.clientWidth * 565) / 1980 + "px"
      }
      if (document.documentElement.clientWidth > 550) {
        this.productWidths = (document.documentElement.clientWidth * 660) / 1980 + "px";
        this.screenwidths = (document.documentElement.clientWidth * 665) / 1980 + "px";
      }
    },
  }
};
</script>
<style lang="scss">
.dialog-yuerin{
  text-align: left;
}
.yuerin-three-bottom-card{
  .el-card__body{
    padding: 39px 24px 39px 24px;
  }
}
.yuerin-nine{
  .yuerin-nine-bottom{
    .yuerin-nine-bottom-card{
      .el-card__body{
        padding: 0px;
      }
    }
  }
}
@media screen and (max-width: 1350px) {
  .yuerin{
    .yuerin-three{
      .yuerin-three-bottom{
        .yuerin-three-bottom-card{
          .el-card__body{
            padding: 24px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1150px) {
  .yuerin{
    .yuerin-three{
      .yuerin-three-bottom{
        .yuerin-three-bottom-card{
          .el-card__body{
            padding: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
.yuerin{
  .yuerin-seven{
    .yuerin-seven-carousel{
      .yuerin-seven-bottom{
        .yuerin-seven-card{
          .el-card__body{
            padding: 15px;
          }
        }
        .yuerin-seven-card-2{
          .el-card__body{
            padding: 15px;
          }
        }
      }
    }
  }
}
}
@media screen and (max-width: 550px) {
.yuerin{
  .yuerin-six{
    .yuerin-six-bottom{
      .el-row{
        display: flow-root;
        .el-col{
          display: inline-block;
          width: 50%;
          padding-left: 5px !important; padding-right: 5px !important;
        }
        div:nth-child(3){
          clear: both;
          display: block;
        }
      }
    }
  }
}
}
</style>
<style lang="scss" scoped>
.yuerin{
  .yuerin-one{
    width: 100%;
    .yuerin-one-carousel{
      background-image:url('../assets/images/banner.jpg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      .yuerin-one-carousel-boxs{
        width: 61%;
        margin: 0 auto;
        .yuerin-one-carousel-boxs-top{
          text-align: left;
          padding-top: 20px;
          .yuerin-one-carousel-boxs-top-left{
            display: inline-block;
            vertical-align: top;
            .yuerin-one-carousel-boxs-top-left-logo1{
              width: 43px;
              height: 43px;
              background-color: #ea5514;
              border-radius: 5px;
              display: inline-block;
              vertical-align: top;
              text-align: center;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-one-carousel-boxs-top-left-logo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
          }
          .yuerin-one-carousel-boxs-top-right{
            float: right;
            display: inline-block;
            vertical-align: top;
            .yuerin-one-carousel-boxs-top-right-small{
              display: none;
              .btnn{
                border: 0px;
                background-color: #ea5514;
                color: #fff;
              }
            }
            .yuerin-one-carousel-boxs-top-right-boxs{
              display: inline-block;
              vertical-align: top;
              i{
                color: #fff;
                font-weight: 100;
                font-size: 20px;
                line-height: 42px;
                padding: 0px 10px 0px 10px;
                cursor: pointer;
              }
              span{
                color: #fff;
                font-weight: 100;
                font-size: 16px;
                line-height: 42px;
                padding: 0px 21px 0px 21px;
                cursor: pointer;
              }
            }
          }
        }
        .yuerin-one-carousel-boxs-bottom{
          text-align: left;
          margin-top: 110px;
          span{
            display: block;
            color: #fff;
            font-size: 36px;
            letter-spacing: 30px;
            font-weight: 100;
            margin: 41px 0px 58px 0px;
          }
          i{
            display: block;
            color: #fff;
            font-size: 50px;
          }
          .yuerin-one-carousel-boxs-bottom-btn{
            background-color: #ea5514;
            border: #ea5514;
            width: 148px;
            height: 52px;
            font-size: 22px;
          }
        }
      }
    }
  }
  .yuerin-two{
    padding-top: 80px;
    .yuerin-two-top{
      span{
        display: block;
        font-weight: 100;
      }
      .yuerin-two-top-ti{
        font-size: 42px;
        margin-bottom: 14px;
      }
      .yuerin-two-top-tx{
        font-size: 20px;
        color: #4e4e4e;
      }
    }
    .yuerin-two-bottom{
      .yuerin-two-carousel{
        background-image:url('../assets/images/product.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-two-carousel-item-one{
          width: 250px;
          position: absolute;
          top: 28%;
          left: 40%;
          .yuerin-two-carousel-item-top{
            .yuerin-two-carousel-item-top-btn{
              width: 200px;
              height: 54px;
              background-color: #ea5514;
              text-align: center;
              border-radius: 50px 50px 0px;
              span{
                line-height: 54px;
                color: #fff;
                font-size: 20px;
                font-weight: 100;
              }
            }
          }
          .yuerin-two-carousel-item-bottom{
            float: right;
          }
        }

        .yuerin-two-carousel-item-two{
          width: 250px;
          position: absolute;
          top: 24%;
          left: 61%;
          .yuerin-two-carousel-item-top{
            text-align: right;
            .yuerin-two-carousel-item-top-btn{
              width: 200px;
              height: 54px;
              background-color: #ea5514;
              border-radius: 50px 50px 50px 0px;
              text-align: center;
              margin-left: 40px;
              span{
                line-height: 54px;
                color: #fff;
                font-size: 20px;
                font-weight: 100;
              }
            }
          }
          .yuerin-two-carousel-item-bottom{
            float: left;
          }
        }

        .yuerin-two-carousel-item-three{
          width: 250px;
          position: absolute;
          top: 74%;
          left: 49%;
          .yuerin-two-carousel-item-top{
            text-align: right;
            .yuerin-two-carousel-item-top-btn{
              width: 200px;
              height: 54px;
              background-color: #ea5514;
              border-radius: 50px 50px 50px 0px;
              text-align: center;
              margin-left: 40px;
              span{
                line-height: 54px;
                color: #fff;
                font-size: 20px;
                font-weight: 100;
              }
            }
          }
          .yuerin-two-carousel-item-bottom{
            float: left;
          }
        }

        .container {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
            }
 
            .container .box {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
 
            .container .box span {
                position: absolute;
                box-sizing: border-box;
                // border: 2px solid #fff;
                border: none;
                background: rgba(234,85,20,0.4);
                border-radius: 50%;
                animation: animate 2s linear infinite;
                animation-delay: calc(0.5s * var(--i))
            }
 
            @keyframes animate {
                0% {
                    width: 0;
                    height: 0;
                }
 
                50% {
                    opacity: 1;
                }
 
                100% {
                    width: 50px;
                    height: 50px;
                    opacity: 0;
                }
            }
      }
    }
  }
  .yuerin-three{
    padding-top: 100px;
    padding-bottom: 100px;
    .yuerin-three-top{
      span{
        font-weight: 100;
        display: block;
      }
      .yuerin-three-top-ti{
        font-size: 42px;
        margin-bottom: 14px;
      }
      .yuerin-three-top-tx{
        font-size: 20px;
        color: #4e4e4e;
      }
    }
    .yuerin-three-bottom{
      width: 61%;
      margin: 0 auto;
      margin-top: 36px;
      .yuerin-three-bottom-card{
        text-align: left;
        cursor: pointer;
        .yuerin-three-bottom-card-left{
          display: inline-block;
          vertical-align: top;
          width: 85px;
          .yuerin-three-bottom-card-left-tb{
            width: 85px;
            height: 85px;
            border-radius: 50%;
            border: 1px solid #cdc6c3;
            text-align: center;
            i{
              line-height: 85px;
              color: #ea5514;
              font-size: 43px;
            }
          }
        }
        .yuerin-three-bottom-card-right{
          display: inline-block;
          vertical-align: top;
          float: right;
          width: calc(100% - 105px);
          span{
            display: block;
          }
          .yuerin-three-bottom-card-right-ti{
            font-size: 22px;
            margin-bottom: 14px;
          }
          .yuerin-three-bottom-card-right-tx{
            font-size: 16px;
            color: #4e4e4e;
          }
        }
      }
    }
  }
  .yuerin-five{
    text-align: left;
    .yuerin-five-carousel{
      background-image:url('../assets/images/bg1.jpg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      .yuerin-five-carousel-box{
        padding-top: 62px;
        width: 61%;
        margin: 0 auto;
        .yuerin-five-carousel-left{
          width: 500px;
          display: inline-block;
          vertical-align: top;
          span{
            font-weight: 100;
            display: block;
          }
          .yuerin-five-carousel-left-ti{
            font-size: 42px;
            margin-bottom: 14px;
          }
          .yuerin-five-carousel-left-tx{
            color: #4e4e4e;
            font-size: 20px;
          }
          .yuerin-five-carousel-left-line{
            width: 67px;
            height: 4px;
            background-color: #ea5514;
            margin: 19px 0px 36px 0px;
          }
          .yuerin-five-carousel-left-te{
            font-size: 15px;
            color: #4e4e4e;
          }
        }
        .yuerin-five-carousel-right{
          text-align: right;
          width: calc(100% - 550px);
          display: inline-block;
          vertical-align: top;
          float: right;
          img{
            width: auto; 
            height: auto; 
            max-width:92%; 
            max-height:92%;
          }
        }
      }
    }

  }
  .yuerin-six{
    padding-top: 100px;
    padding-bottom: 100px;
    .yuerin-six-top{
      span{
        display: block;
        font-weight: 100;
      }
      .yuerin-six-top-ti{
        font-size: 42px;
        margin-bottom: 14px;
      }
      .yuerin-six-top-tx{
        font-size: 20px;
        color: #4e4e4e;
      }
    }
    .yuerin-six-bottom-samll{
      display: none;
      width: 61%;
      margin: 0 auto;
      margin-top: 42px;
      .yuerin-six-bottom-card{
        border: 0px;
        box-shadow:0 0px 0px;
        .yuerin-six-bottom-image{
          width: 100%;
        }
        .yuerin-six-bottom-card-boxs{
          text-align: left;
          .yuerin-six-bottom-card-boxs-top{
            margin: 17px 0px 12px 0px;
            span{
              font-size: 22px;
              font-weight: 100;
            }
            .yuerin-six-bottom-card-boxs-top-ti2{
              color: #ea5514;
            }
          }
          .yuerin-six-bottom-card-boxs-bottom{
            span{
              color: #4e4e4e;
              font-size: 16px;
              font-weight: 100;
            }
          }
        }
      }
    }
    .yuerin-six-bottom{
      width: 61%;
      margin: 0 auto;
      margin-top: 42px;
      .yuerin-six-bottom-card{
        border: 0px;
        box-shadow:0 0px 0px;
        .yuerin-six-bottom-image{
          width: 100%;
        }
        .yuerin-six-bottom-card-boxs{
          text-align: left;
          .yuerin-six-bottom-card-boxs-top{
            margin: 17px 0px 12px 0px;
            span{
              font-size: 22px;
              font-weight: 100;
            }
            .yuerin-six-bottom-card-boxs-top-ti2{
              color: #ea5514;
            }
          }
          .yuerin-six-bottom-card-boxs-bottom{
            span{
              color: #4e4e4e;
              font-size: 16px;
              font-weight: 100;
            }
          }
        }
      }
    }
  }
  .yuerin-seven{
    .yuerin-seven-carousel{
      background-image:url('../assets/images/bg2.jpg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      .yuerin-seven-top{
        margin-top: 100px;
        span{
          font-weight: 100;
          display: block;
        }
        .yuerin-seven-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-seven-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-seven-bottom{
        width: 61%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-seven-card-2{
          text-align: left;
          min-height: 123px;
          border-radius: 30px 0px;
          .yuerin-seven-card-ti{
            margin-bottom: 10px;
            span{
              color: #ea5514;
              font-size: 24px;
            }
          }
          .yuerin-seven-card-tx{
            span{
              color: #4e4e4e;
              font-size: 16px;
            }
          }
        }
        .yuerin-seven-card{
          text-align: left;
          border-radius: 0px 30px;
          min-height: 123px;
          .yuerin-seven-card-ti{
            margin-bottom: 10px;
            span{
              color: #ea5514;
              font-size: 24px;
            }
          }
          .yuerin-seven-card-tx{
            span{
              color: #4e4e4e;
              font-size: 16px;
            }
          }
        }
      }
      
    }
  }
  .yuerin-eight{
    .yuerin-eight-carousel{
      background-image:url('../assets/images/bg3.jpg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      .yuerin-eight-top{
        margin-top: 80px;
        span{
          font-size: 42px;
          font-weight: 100;
          color: #fff;
        }
      }
      .yuerin-eight-bottom{
        width: 61%;
        margin:  0 auto;
        margin-top: 41px;
        .yuerin-eight-bottom-boxs{
          display: inline-block;
          vertical-align: top;
          width: 25%;
          span{
            font-weight: 100;
            color: #fff;
          }
          .yuerin-eight-bottom-boxs-ti{
            font-size: 64px;
          }
          .yuerin-eight-bottom-boxs-tx{
            font-size: 24px;
          }
        }
      }
    }
  }
  .yuerin-nine{
    padding-top: 100px;
    padding-bottom: 100px;
    .yuerin-nine-top{
      span{
        display: block;
        font-weight: 100;
      }
      .yuerin-nine-top-ti{
        font-size: 42px;
        margin-bottom: 14px;
      }
      .yuerin-nine-top-tx{
        font-size: 20px;
        color: #4e4e4e;
      }
    }
    .yuerin-nine-bottom{
      width: 61%;
      margin: 0 auto;
      .yuerin-nine-bottom-card{
        border-radius: 15px;
        margin-top: 43px;
        text-align: left;
        background-color: #ea5514;
        min-height: 166px;
        padding-left: 27px;
        .yuerin-nine-bottom-card-top{
          .yuerin-nine-bottom-card-top-left{
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 80px);
            span{
              font-size: 28px;
              color: #fff;
              font-weight: 100;
              line-height: 80px;
            }
          }
          .yuerin-nine-bottom-card-top-right{
            display: inline-block;
            width: 80px;
            vertical-align: top;
            i{
              font-size: 55px;
              color: #fff;
              opacity: 0.3;
              line-height: 80px;
            }
          }
        }
        .yuerin-nine-bottom-card-bottom{
          span{
            font-weight: 100;
            color: #fff;
            display: block;
          }
          .yuerin-nine-bottom-card-bottom-ti{
            font-size: 16px;
            margin-bottom: 20px;
            padding-right: 15px;
          }
          .yuerin-nine-bottom-card-bottom-tx{
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .yuerin-ten{
    background-color: #242424;
    height: 200px;
    padding-top: 65px;
    .yuerin-ten-box{
      text-align: left;
      width: 61%;
      margin: 0 auto;
     .yuerin-ten-boxs{
       display: inline-block;
       vertical-align: top;
       padding: 0 23px 0px 23px;
       border-right:1px solid #A3A3A3;
       .yuerin-ten-boxs-logos{
          .yuerin-ten-boxs-lo1{
            text-align: center;
            background-color: #ea5514;
            width: 42px;
            height: 42px;
            display: inline-block;
            vertical-align: top;
            border-radius: 5px;
            i{
              line-height: 42px;
              color: #fff;
              font-size: 30px;
            }
          }
          .yuerin-ten-boxs-lo2{
            display: inline-block;
            vertical-align: top;
            i{
              margin-left: 15px;
              line-height: 42px;
              color: #ea5514;
              font-size: 30px;
            }
          }
       }
       .yuerin-ten-boxs-ti{
         color: #fff;
       }
       span{
         display: block;
         font-size: 13px;
         color: #A3A3A3;
         margin-bottom: 3px;
         cursor: pointer;
         i{
           font-size: 13px;
         }
       }
     } 
     .yuerin-ten-boxs-one{
       width: 350px;
        border-right:1px solid #A3A3A3;
        .yuerin-ten-boxs-logos-bo{
          margin-top: 12px;
          span{
            color: #A3A3A3;
            font-size: 13px;
          }
       }
     }
     .yuerin-ten-boxs-two{
       width: calc(100% - 645px);
     }
    }
    .yuerin-ten-over{
      color: #A3A3A3;
      font-size: 13px;
      a{
        color: #A3A3A3;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1800px) {
  .yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 70%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: inline-block;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 16px;
                  line-height: 42px;
                  padding: 0px 21px 0px 21px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 110px;
            span{
              display: block;
              color: #fff;
              font-size: 36px;
              letter-spacing: 30px;
              font-weight: 100;
              margin: 41px 0px 58px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 50px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 148px;
              height: 52px;
              font-size: 22px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-two-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 250px;
            position: absolute;
            top: 28%;
            left: 40%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 250px;
            position: absolute;
            top: 24%;
            left: 61%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 250px;
            position: absolute;
            top: 74%;
            left: 49%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 100;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 70%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          text-align: left;
          cursor: pointer;
          .yuerin-three-bottom-card-left{
            display: inline-block;
            vertical-align: top;
            width: 85px;
            .yuerin-three-bottom-card-left-tb{
              width: 85px;
              height: 85px;
              border-radius: 50%;
              border: 1px solid #cdc6c3;
              text-align: center;
              i{
                line-height: 85px;
                color: #ea5514;
                font-size: 43px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: inline-block;
            vertical-align: top;
            float: right;
            width: calc(100% - 105px);
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 22px;
              margin-bottom: 14px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 16px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-five-carousel-box{
          padding-top: 62px;
          width: 70%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 500px;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 100;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 42px;
              margin-bottom: 14px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 20px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 19px 0px 36px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 15px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: inline-block;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width:92%; 
              max-height:92%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-six-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 70%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 17px 0px 12px 0px;
              span{
                font-size: 22px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 16px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-seven-top{
          margin-top: 43px;
          span{
            font-weight: 100;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 42px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 20px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 70%;
          margin: 0 auto;
          margin-top: 42px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 123px;
            border-radius: 30px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 10px;
              span{
                color: #ea5514;
                font-size: 24px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 16px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 30px;
            min-height: 123px;
            .yuerin-seven-card-ti{
              margin-bottom: 10px;
              span{
                color: #ea5514;
                font-size: 24px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 16px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-eight-top{
          margin-top: 80px;
          span{
            font-size: 42px;
            font-weight: 100;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 70%;
          margin:  0 auto;
          margin-top: 41px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 64px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 24px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-nine-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 70%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 28px;
                color: #fff;
                font-weight: 100;
                line-height: 80px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 55px;
                color: #fff;
                opacity: 0.3;
                line-height: 80px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 16px;
              margin-bottom: 20px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 14px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      background-color: #242424;
      height: 200px;
      padding-top: 65px;
      .yuerin-ten-box{
        text-align: left;
        width: 70%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        display: inline-block;
        vertical-align: top;
        padding: 0 23px 0px 23px;
        border-right:1px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
        width: 370px;
          border-right:1px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: calc(100% - 670px);
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 80%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: inline-block;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 16px;
                  line-height: 42px;
                  padding: 0px 21px 0px 21px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 110px;
            span{
              display: block;
              color: #fff;
              font-size: 36px;
              letter-spacing: 30px;
              font-weight: 100;
              margin: 41px 0px 58px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 50px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 148px;
              height: 52px;
              font-size: 22px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-two-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 250px;
            position: absolute;
            top: 25%;
            left: 38%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 250px;
            position: absolute;
            top: 22%;
            left: 61%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 250px;
            position: absolute;
            top: 73%;
            left: 48.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 100;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          text-align: left;
          cursor: pointer;
          .yuerin-three-bottom-card-left{
            display: inline-block;
            vertical-align: top;
            width: 85px;
            .yuerin-three-bottom-card-left-tb{
              width: 85px;
              height: 85px;
              border-radius: 50%;
              border: 1px solid #cdc6c3;
              text-align: center;
              i{
                line-height: 85px;
                color: #ea5514;
                font-size: 43px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: inline-block;
            vertical-align: top;
            float: right;
            width: calc(100% - 105px);
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 22px;
              margin-bottom: 14px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 16px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-five-carousel-box{
          padding-top: 62px;
          width: 80%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 500px;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 100;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 42px;
              margin-bottom: 14px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 20px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 19px 0px 36px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 15px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: inline-block;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 63%;
              max-height: 63%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-six-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 17px 0px 12px 0px;
              span{
                font-size: 22px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 16px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-seven-top{
          margin-top: 27px;
          span{
            font-weight: 100;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 42px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 20px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 80%;
          margin: 0 auto;
          margin-top: 20px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 123px;
            border-radius: 30px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 10px;
              span{
                color: #ea5514;
                font-size: 24px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 16px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 30px;
            min-height: 123px;
            .yuerin-seven-card-ti{
              margin-bottom: 10px;
              span{
                color: #ea5514;
                font-size: 24px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 16px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-eight-top{
          margin-top: 80px;
          span{
            font-size: 42px;
            font-weight: 100;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 80%;
          margin:  0 auto;
          margin-top: 41px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 64px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 24px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-nine-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 80%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 28px;
                color: #fff;
                font-weight: 100;
                line-height: 80px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 55px;
                color: #fff;
                opacity: 0.3;
                line-height: 80px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 16px;
              margin-bottom: 20px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 14px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      background-color: #242424;
      height: 200px;
      padding-top: 65px;
      .yuerin-ten-box{
        text-align: left;
        width: 80%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        display: inline-block;
        vertical-align: top;
        padding: 0 23px 0px 23px;
        border-right:1px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
        width: 370px;
          border-right:1px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: calc(100% - 670px);
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 1473px) {
  .yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 80%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: inline-block;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 16px;
                  line-height: 42px;
                  padding: 0px 21px 0px 21px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 65px;
            span{
              display: block;
              color: #fff;
              font-size: 36px;
              letter-spacing: 30px;
              font-weight: 100;
              margin: 41px 0px 58px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 50px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 148px;
              height: 52px;
              font-size: 22px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-two-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 250px;
            position: absolute;
            top: 25%;
            left: 38%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 250px;
            position: absolute;
            top: 22%;
            left: 61%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 250px;
            position: absolute;
            top: 73%;
            left: 48.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 100;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          text-align: left;
          cursor: pointer;
          .yuerin-three-bottom-card-left{
            display: inline-block;
            vertical-align: top;
            width: 85px;
            .yuerin-three-bottom-card-left-tb{
              width: 85px;
              height: 85px;
              border-radius: 50%;
              border: 1px solid #cdc6c3;
              text-align: center;
              i{
                line-height: 85px;
                color: #ea5514;
                font-size: 43px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: inline-block;
            vertical-align: top;
            float: right;
            width: calc(100% - 105px);
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 22px;
              margin-bottom: 14px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 16px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-five-carousel-box{
          padding-top: 62px;
          width: 80%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 500px;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 100;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 42px;
              margin-bottom: 14px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 20px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 19px 0px 36px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 15px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: inline-block;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 63%;
              max-height: 63%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-six-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 17px 0px 12px 0px;
              span{
                font-size: 22px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 16px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-seven-top{
          margin-top: 27px;
          span{
            font-weight: 100;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 42px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 20px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 80%;
          margin: 0 auto;
          margin-top: 20px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 100px;
            max-height: 100px;
            border-radius: 15px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 20px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 14px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 15px;
            min-height: 100px;
            max-height: 100px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 20px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 14px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-eight-top{
          margin-top: 40px;
          span{
            font-size: 42px;
            font-weight: 100;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 80%;
          margin:  0 auto;
          margin-top: 41px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 64px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 24px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-nine-top-ti{
          font-size: 42px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 20px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 80%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 28px;
                color: #fff;
                font-weight: 100;
                line-height: 80px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 55px;
                color: #fff;
                opacity: 0.3;
                line-height: 80px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 16px;
              margin-bottom: 20px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 14px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      background-color: #242424;
      height: 200px;
      padding-top: 65px;
      .yuerin-ten-box{
        text-align: left;
        width: 80%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        display: inline-block;
        vertical-align: top;
        padding: 0 23px 0px 23px;
        border-right:1px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
        width: 370px;
          border-right:1px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: calc(100% - 670px);
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 1350px) {
 .yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 80%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: inline-block;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 16px;
                  line-height: 42px;
                  padding: 0px 21px 0px 21px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 65px;
            span{
              display: block;
              color: #fff;
              font-size: 24px;
              letter-spacing: 30px;
              font-weight: 100;
              margin: 41px 0px 58px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 50px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 148px;
              height: 52px;
              font-size: 22px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-two-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 250px;
            position: absolute;
            top: 25%;
            left: 38%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 250px;
            position: absolute;
            top: 22%;
            left: 61%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 250px;
            position: absolute;
            top: 73%;
            left: 48.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 200px;
                height: 54px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 54px;
                  color: #fff;
                  font-size: 20px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 100;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          text-align: left;
          cursor: pointer;
          .yuerin-three-bottom-card-left{
            display: inline-block;
            vertical-align: top;
            width: 85px;
            .yuerin-three-bottom-card-left-tb{
              width: 85px;
              height: 85px;
              border-radius: 50%;
              border: 1px solid #cdc6c3;
              text-align: center;
              i{
                line-height: 85px;
                color: #ea5514;
                font-size: 43px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: inline-block;
            vertical-align: top;
            float: right;
            width: calc(100% - 105px);
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 18px;
              margin-bottom: 14px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 14px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-five-carousel-box{
          padding-top: 30px;
          width: 80%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 500px;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 100;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 35px;
              margin-bottom: 14px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 18px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 19px 0px 36px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 15px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: inline-block;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 63%;
              max-height: 63%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-six-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 17px 0px 12px 0px;
              span{
                font-size: 22px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 16px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-seven-top{
          margin-top: 27px;
          span{
            font-weight: 100;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 35px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 18px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 80%;
          margin: 0 auto;
          margin-top: 20px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 100px;
            max-height: 100px;
            border-radius: 15px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 20px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 14px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 15px;
            min-height: 100px;
            max-height: 100px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 20px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 14px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-eight-top{
          margin-top: 40px;
          span{
            font-size: 35px;
            font-weight: 100;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 80%;
          margin:  0 auto;
          margin-top: 41px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 64px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 24px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-nine-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 80%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 28px;
                color: #fff;
                font-weight: 100;
                line-height: 80px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 55px;
                color: #fff;
                opacity: 0.3;
                line-height: 80px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 16px;
              margin-bottom: 20px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 14px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      background-color: #242424;
      height: 200px;
      padding-top: 65px;
      .yuerin-ten-box{
        text-align: left;
        width: 80%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        display: inline-block;
        vertical-align: top;
        padding: 0 23px 0px 23px;
        border-right:1px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
        width: 370px;
          border-right:1px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: calc(100% - 670px);
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 80%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: inline-block;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 16px;
                  line-height: 42px;
                  padding: 0px 21px 0px 21px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 40px;
            span{
              display: block;
              color: #fff;
              font-size: 24px;
              letter-spacing: 30px;
              font-weight: 100;
              margin: 30px 0px 45px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 40px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 100px;
              height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-two-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 200px;
            position: absolute;
            top: 25%;
            left: 38%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 250px;
            position: absolute;
            top: 22%;
            left: 61%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 200px;
            position: absolute;
            top: 73%;
            left: 48.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 100;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          text-align: left;
          cursor: pointer;
          .yuerin-three-bottom-card-left{
            display: inline-block;
            vertical-align: top;
            width: 85px;
            .yuerin-three-bottom-card-left-tb{
              width: 85px;
              height: 85px;
              border-radius: 50%;
              border: 1px solid #cdc6c3;
              text-align: center;
              i{
                line-height: 85px;
                color: #ea5514;
                font-size: 43px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: inline-block;
            vertical-align: top;
            float: right;
            width: calc(100% - 105px);
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 18px;
              margin-bottom: 14px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 14px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-five-carousel-box{
          padding-top: 30px;
          width: 80%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 500px;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 100;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 24px;
              margin-bottom: 14px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 14px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 19px 0px 36px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 13px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: inline-block;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 92%;
              max-height: 92%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-six-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 17px 0px 12px 0px;
              span{
                font-size: 22px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 16px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-seven-top{
          margin-top: 14px;
          span{
            font-weight: 100;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 35px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 18px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 80%;
          margin: 0 auto;
          margin-top: 20px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 100px;
            max-height: 100px;
            border-radius: 15px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 20px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 14px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 15px;
            min-height: 100px;
            max-height: 100px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 20px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 14px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-eight-top{
          margin-top: 40px;
          span{
            font-size: 35px;
            font-weight: 100;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 80%;
          margin:  0 auto;
          margin-top: 30px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 35px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-nine-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 80%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            margin-top: 10px;
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 22px;
                color: #fff;
                font-weight: 100;
                line-height: 50px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 40px;
                color: #fff;
                opacity: 0.3;
                line-height: 50px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 16px;
              margin-bottom: 20px;
              margin-right: 27px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 14px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      background-color: #242424;
      height: 200px;
      padding-top: 65px;
      .yuerin-ten-box{
        text-align: left;
        width: 80%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        display: inline-block;
        vertical-align: top;
        padding: 0 23px 0px 23px;
        border-right:1px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
        width: 370px;
          border-right:1px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: calc(100% - 670px);
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 1150px) {
.yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 80%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: inline-block;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 14px;
                  line-height: 42px;
                  padding: 0px 21px 0px 21px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 40px;
            span{
              display: block;
              color: #fff;
              font-size: 24px;
              letter-spacing: 30px;
              font-weight: 100;
              margin: 30px 0px 45px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 40px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 100px;
              height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-two-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 200px;
            position: absolute;
            top: 25%;
            left: 38%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 250px;
            position: absolute;
            top: 22%;
            left: 61%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 200px;
            position: absolute;
            top: 73%;
            left: 48.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 100;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          text-align: left;
          cursor: pointer;
          height: 95px;
          .yuerin-three-bottom-card-left{
            display: inline-block;
            vertical-align: top;
            width: 50px;
            .yuerin-three-bottom-card-left-tb{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              border: 1px solid #cdc6c3;
              text-align: center;
              i{
                line-height: 50px;
                color: #ea5514;
                font-size: 24px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: inline-block;
            vertical-align: top;
            float: right;
            width: calc(100% - 68px);
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 16px;
              margin-bottom: 3px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 12px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-five-carousel-box{
          padding-top: 30px;
          width: 80%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 500px;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 100;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 24px;
              margin-bottom: 14px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 14px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 19px 0px 36px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 13px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: inline-block;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 92%;
              max-height: 92%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-six-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 17px 0px 12px 0px;
              span{
                font-size: 22px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 16px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-seven-top{
          margin-top: 14px;
          span{
            font-weight: 100;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 35px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 18px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 80%;
          margin: 0 auto;
          margin-top: 20px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 100px;
            max-height: 100px;
            border-radius: 15px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 20px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 14px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 15px;
            min-height: 100px;
            max-height: 100px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 20px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 14px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-eight-top{
          margin-top: 40px;
          span{
            font-size: 35px;
            font-weight: 100;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 80%;
          margin:  0 auto;
          margin-top: 30px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 35px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 100;
        }
        .yuerin-nine-top-ti{
          font-size: 35px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 18px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 80%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            margin-top: 10px;
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 22px;
                color: #fff;
                font-weight: 100;
                line-height: 50px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 40px;
                color: #fff;
                opacity: 0.3;
                line-height: 50px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 16px;
              margin-bottom: 20px;
              margin-right: 27px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 14px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      padding-bottom: 20px;
      background-color: #242424;
      height: auto;
      padding-top: 65px;
      .yuerin-ten-box{
        text-align: left;
        width: 80%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        margin-bottom: 15px;
        display: block;
        vertical-align: top;
        width: 100%;
        padding: 0 23px 0px 23px;
        border-right:0px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
          width: 300px;
          border-right:0px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: 100%;
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 80%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: inline-block;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 14px;
                  line-height: 42px;
                  padding: 0px 21px 0px 21px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 40px;
            span{
              display: block;
              color: #fff;
              font-size: 16px;
              letter-spacing: 15px;
              font-weight: 100;
              margin: 20px 0px 25px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 30px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 100px;
              height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-two-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 200px;
            position: absolute;
            top: 18%;
            left: 33%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 250px;
            position: absolute;
            top: 17%;
            left: 60%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 200px;
            position: absolute;
            top: 67%;
            left: 47.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 300;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          text-align: left;
          cursor: pointer;
          height: 95px;
          .yuerin-three-bottom-card-left{
            display: inline-block;
            vertical-align: top;
            width: 50px;
            .yuerin-three-bottom-card-left-tb{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              border: 1px solid #cdc6c3;
              text-align: center;
              i{
                line-height: 50px;
                color: #ea5514;
                font-size: 24px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: inline-block;
            vertical-align: top;
            float: right;
            width: calc(100% - 68px);
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 16px;
              margin-bottom: 3px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 12px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-five-carousel-box{
          padding-top: 30px;
          width: 80%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 500px;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 300;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 24px;
              margin-bottom: 14px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 14px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 13px 0px 15px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 13px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: inline-block;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 150%;
              max-width: 150%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-six-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 5px 0px 5px 0px;
              span{
                font-size: 18px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 13px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-seven-top{
          margin-top: 14px;
          span{
            font-weight: 300;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 24px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 14px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 80%;
          margin: 0 auto;
          margin-top: 20px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 100px;
            max-height: 100px;
            border-radius: 15px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 16px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 12px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 15px;
            min-height: 100px;
            max-height: 100px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 16px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 12px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-eight-top{
          margin-top: 40px;
          span{
            font-size: 24px;
            font-weight: 300;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 80%;
          margin:  0 auto;
          margin-top: 30px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 35px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-nine-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 80%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            margin-top: 10px;
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 18px;
                color: #fff;
                font-weight: 100;
                line-height: 50px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 40px;
                color: #fff;
                opacity: 0.3;
                line-height: 50px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 14px;
              margin-bottom: 20px;
              margin-right: 27px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 12px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      padding-bottom: 20px;
      background-color: #242424;
      height: auto;
      padding-top: 40px;
      .yuerin-ten-box{
        text-align: left;
        width: 80%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        margin-bottom: 15px;
        display: block;
        vertical-align: top;
        width: 100%;
        padding: 0 23px 0px 23px;
        border-right:0px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
          width: 300px;
          border-right:0px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: 100%;
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 80%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: inline-block;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 12px;
                  line-height: 42px;
                  padding: 0px 10px 0px 10px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 22px;
            span{
              display: block;
              color: #fff;
              font-size: 15px;
              letter-spacing: 12px;
              font-weight: 100;
              margin: 20px 0px 25px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 24px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 72px;
              height: 30px;
              font-size: 14px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-two-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 200px;
            position: absolute;
            top: 16%;
            left: 30%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 250px;
            position: absolute;
            top: 13%;
            left: 60%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 200px;
            position: absolute;
            top: 63%;
            left: 46.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 160px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 15px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 300;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          cursor: pointer;
          height: 150px;
          text-align: center;
          .yuerin-three-bottom-card-left{
            display: block;
            vertical-align: top;
            width: 100%;
            .yuerin-three-bottom-card-left-tb{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-bottom: 10px !important;
              border: 1px solid #cdc6c3;
              text-align: center;
              margin: 0 auto;
              i{
                line-height: 50px;
                color: #ea5514;
                font-size: 24px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: block;
            vertical-align: top;
            float: right;
            width: 100%;
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 16px;
              margin-bottom: 3px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 12px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-five-carousel-box{
          padding-top: 30px;
          width: 80%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 100%;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 300;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 24px;
              margin-bottom: 14px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 14px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 13px 0px 15px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 13px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: none;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 150%;
              max-width: 150%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-six-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 80%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 5px 0px 5px 0px;
              span{
                font-size: 18px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 13px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-seven-top{
          margin-top: 14px;
          span{
            font-weight: 300;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 24px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 14px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 80%;
          margin: 0 auto;
          margin-top: 20px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 100px;
            max-height: 100px;
            border-radius: 15px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 16px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 12px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 15px;
            min-height: 100px;
            max-height: 100px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 16px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 12px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-eight-top{
          margin-top: 40px;
          span{
            font-size: 24px;
            font-weight: 300;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 80%;
          margin:  0 auto;
          margin-top: 30px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 35px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-nine-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 80%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            margin-top: 10px;
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 18px;
                color: #fff;
                font-weight: 100;
                line-height: 50px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 40px;
                color: #fff;
                opacity: 0.3;
                line-height: 50px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 14px;
              margin-bottom: 20px;
              margin-right: 27px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 12px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      padding-bottom: 20px;
      background-color: #242424;
      height: auto;
      padding-top: 40px;
      .yuerin-ten-box{
        text-align: left;
        width: 80%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        margin-bottom: 15px;
        display: block;
        vertical-align: top;
        width: 100%;
        padding: 0 23px 0px 23px;
        border-right:0px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
          width: 300px;
          border-right:0px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: 100%;
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 660px) {
.yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% auto;
        .yuerin-one-carousel-boxs{
          width: 90%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-small{
                display: inline-block;
                .btnn{
                  background-color: #ea5514;
                  color: #fff;
                }
              }
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: none;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 12px;
                  line-height: 42px;
                  padding: 0px 10px 0px 10px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 22px;
            span{
              display: block;
              color: #fff;
              font-size: 12px;
              letter-spacing: 9px;
              font-weight: 100;
              margin: 10px 0px 10px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 20px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 72px;
              height: 30px;
              font-size: 12px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-two-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% auto;
          .yuerin-two-carousel-item-one{
            width: 170px;
            position: absolute;
            top: 9%;
            left: 28%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 128px;
                height: 40px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 12px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 170px;
            position: absolute;
            top: 7%;
            left: 59%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 128px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 12px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 170px;
            position: absolute;
            top: 56%;
            left: 45.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 128px;
                height: 40px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 40px;
                  color: #fff;
                  font-size: 12px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 300;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 90%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          cursor: pointer;
          height: 165px;
          text-align: center;
          .yuerin-three-bottom-card-left{
            display: block;
            vertical-align: top;
            width: 100%;
            .yuerin-three-bottom-card-left-tb{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-bottom: 10px !important;
              border: 1px solid #cdc6c3;
              text-align: center;
              margin: 0 auto;
              i{
                line-height: 50px;
                color: #ea5514;
                font-size: 24px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: block;
            vertical-align: top;
            float: right;
            width: 100%;
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 16px;
              margin-bottom: 3px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 12px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-five-carousel-box{
          padding-top: 15px;
          width: 90%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 100%;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 300;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 20px;
              margin-bottom: 10px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 13px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 10px 0px 10px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 12px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: none;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 150%;
              max-width: 150%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-six-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom{
        width: 90%;
        margin: 0 auto;
        margin-top: 42px;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 5px 0px 5px 0px;
              span{
                font-size: 18px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 13px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-seven-top{
          margin-top: 14px;
          span{
            font-weight: 300;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 24px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 14px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 90%;
          margin: 0 auto;
          margin-top: 20px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 115px;
            max-height: 115px;
            border-radius: 15px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 16px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 12px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 15px;
            min-height: 115px;
            max-height: 115px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 16px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 12px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-eight-top{
          margin-top: 40px;
          span{
            font-size: 24px;
            font-weight: 300;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 90%;
          margin:  0 auto;
          margin-top: 30px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 35px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-nine-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 90%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 27px;
          .yuerin-nine-bottom-card-top{
            margin-top: 10px;
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 80px);
              span{
                font-size: 16px;
                color: #fff;
                font-weight: 100;
                line-height: 50px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              i{
                font-size: 40px;
                color: #fff;
                opacity: 0.3;
                line-height: 50px;
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 12px;
              margin-bottom: 20px;
              margin-right: 27px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 12px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      padding-bottom: 20px;
      background-color: #242424;
      height: auto;
      padding-top: 40px;
      .yuerin-ten-box{
        text-align: left;
        width: 90%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        margin-bottom: 15px;
        display: block;
        vertical-align: top;
        width: 100%;
        padding: 0 23px 0px 23px;
        border-right:0px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
          width: 300px;
          border-right:0px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: 100%;
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .yuerin{
    .yuerin-one{
      width: 100%;
      .yuerin-one-carousel{
        background-image:url('../assets/images/banner.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-one-carousel-boxs{
          width: 90%;
          margin: 0 auto;
          .yuerin-one-carousel-boxs-top{
            text-align: left;
            padding-top: 20px;
            .yuerin-one-carousel-boxs-top-left{
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-left-logo1{
                width: 43px;
                height: 43px;
                background-color: #ea5514;
                border-radius: 5px;
                display: inline-block;
                vertical-align: top;
                text-align: center;
                i{
                  line-height: 42px;
                  color: #fff;
                  font-size: 30px;
                }
              }
              .yuerin-one-carousel-boxs-top-left-logo2{
                display: inline-block;
                vertical-align: top;
                i{
                  margin-left: 15px;
                  line-height: 42px;
                  color: #ea5514;
                  font-size: 30px;
                }
              }
            }
            .yuerin-one-carousel-boxs-top-right{
              float: right;
              display: inline-block;
              vertical-align: top;
              .yuerin-one-carousel-boxs-top-right-small{
                display: inline-block;
                .btnn{
                  background-color: #ea5514;
                  color: #fff;
                }
              }
              .yuerin-one-carousel-boxs-top-right-boxs{
                display: none;
                vertical-align: top;
                span{
                  color: #fff;
                  font-weight: 100;
                  font-size: 12px;
                  line-height: 42px;
                  padding: 0px 10px 0px 10px;
                  cursor: pointer;
                }
              }
            }
          }
          .yuerin-one-carousel-boxs-bottom{
            text-align: left;
            margin-top: 16px;
            span{
              display: block;
              color: #fff;
              font-size: 12px;
              letter-spacing: 9px;
              font-weight: 100;
              margin: 10px 0px 10px 0px;
            }
            i{
              display: block;
              color: #fff;
              font-size: 16px;
            }
            .yuerin-one-carousel-boxs-bottom-btn{
              background-color: #ea5514;
              border: #ea5514;
              width: 62px;
              height: 25px;
              font-size: 12px;
            }
          }
        }
      }
    }
    .yuerin-two{
      padding-top: 80px;
      .yuerin-two-top{
        margin-bottom: 15px;
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-two-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-two-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-two-bottom{
        .yuerin-two-carousel{
          background-image:url('../assets/images/product.jpg');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          .yuerin-two-carousel-item-one{
            width: 170px;
            position: absolute;
            top: 8%;
            left: 12%;
            .yuerin-two-carousel-item-top{
              .yuerin-two-carousel-item-top-btn{
                width: 128px;
                height: 30px;
                background-color: #ea5514;
                text-align: center;
                border-radius: 50px 50px 0px;
                span{
                  line-height: 30px;
                  color: #fff;
                  font-size: 12px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: right;
            }
          }

          .yuerin-two-carousel-item-two{
            width: 170px;
            position: absolute;
            top: 1%;
            left: 58%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 128px;
                height: 30px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 30px;
                  color: #fff;
                  font-size: 12px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .yuerin-two-carousel-item-three{
            width: 170px;
            position: absolute;
            top: 50%;
            left: 45.5%;
            .yuerin-two-carousel-item-top{
              text-align: right;
              .yuerin-two-carousel-item-top-btn{
                width: 128px;
                height: 30px;
                background-color: #ea5514;
                border-radius: 50px 50px 50px 0px;
                text-align: center;
                margin-left: 40px;
                span{
                  line-height: 30px;
                  color: #fff;
                  font-size: 12px;
                  font-weight: 100;
                }
              }
            }
            .yuerin-two-carousel-item-bottom{
              float: left;
            }
          }

          .container {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: row;
              }
  
              .container .box {
                  width: 50px;
                  height: 50px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
  
              .container .box span {
                  position: absolute;
                  box-sizing: border-box;
                  // border: 2px solid #fff;
                  border: none;
                  background: rgba(234,85,20,0.4);
                  border-radius: 50%;
                  animation: animate 2s linear infinite;
                  animation-delay: calc(0.5s * var(--i))
              }
  
              @keyframes animate {
                  0% {
                      width: 0;
                      height: 0;
                  }
  
                  50% {
                      opacity: 1;
                  }
  
                  100% {
                      width: 50px;
                      height: 50px;
                      opacity: 0;
                  }
              }
        }
      }
    }
    .yuerin-three{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-three-top{
        span{
          font-weight: 300;
          display: block;
        }
        .yuerin-three-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-three-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-three-bottom{
        width: 90%;
        margin: 0 auto;
        margin-top: 36px;
        .yuerin-three-bottom-card{
          cursor: pointer;
          height: 200px;
          text-align: center;
          .yuerin-three-bottom-card-left{
            display: block;
            vertical-align: top;
            width: 100%;
            .yuerin-three-bottom-card-left-tb{
              width: 50px;
              height: 50px;
              border-radius: 50%;
              margin-bottom: 10px !important;
              border: 1px solid #cdc6c3;
              text-align: center;
              margin: 0 auto;
              i{
                line-height: 50px;
                color: #ea5514;
                font-size: 24px;
              }
            }
          }
          .yuerin-three-bottom-card-right{
            display: block;
            vertical-align: top;
            float: right;
            width: 100%;
            span{
              display: block;
            }
            .yuerin-three-bottom-card-right-ti{
              font-size: 16px;
              margin-bottom: 3px;
            }
            .yuerin-three-bottom-card-right-tx{
              font-size: 12px;
              color: #4e4e4e;
            }
          }
        }
      }
    }
    .yuerin-five{
      text-align: left;
      .yuerin-five-carousel{
        background-image:url('../assets/images/bg1.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-five-carousel-box{
          padding-top: 15px;
          width: 90%;
          margin: 0 auto;
          .yuerin-five-carousel-left{
            width: 100%;
            display: inline-block;
            vertical-align: top;
            span{
              font-weight: 300;
              display: block;
            }
            .yuerin-five-carousel-left-ti{
              font-size: 20px;
              margin-bottom: 10px;
            }
            .yuerin-five-carousel-left-tx{
              color: #4e4e4e;
              font-size: 13px;
            }
            .yuerin-five-carousel-left-line{
              width: 67px;
              height: 4px;
              background-color: #ea5514;
              margin: 10px 0px 10px 0px;
            }
            .yuerin-five-carousel-left-te{
              font-size: 12px;
              color: #4e4e4e;
            }
          }
          .yuerin-five-carousel-right{
            width: calc(100% - 550px);
            display: none;
            vertical-align: top;
            float: right;
            img{
              width: auto; 
              height: auto; 
              max-width: 150%;
              max-width: 150%;
            }
          }
        }
      }

    }
    .yuerin-six{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-six-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-six-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-six-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-six-bottom-samll{
        width: 90%;
        margin: 0 auto;
        margin-top: 42px;
        display: block;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 5px 0px 5px 0px;
              span{
                font-size: 18px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 13px;
                font-weight: 100;
              }
            }
          }
        }
      }
      .yuerin-six-bottom{
        width: 90%;
        margin: 0 auto;
        margin-top: 42px;
        display: none;
        .yuerin-six-bottom-card{
          border: 0px;
          box-shadow:0 0px 0px;
          .yuerin-six-bottom-image{
            width: 100%;
          }
          .yuerin-six-bottom-card-boxs{
            text-align: left;
            .yuerin-six-bottom-card-boxs-top{
              margin: 5px 0px 5px 0px;
              span{
                font-size: 18px;
                font-weight: 100;
              }
              .yuerin-six-bottom-card-boxs-top-ti2{
                color: #ea5514;
              }
            }
            .yuerin-six-bottom-card-boxs-bottom{
              span{
                color: #4e4e4e;
                font-size: 13px;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
    .yuerin-seven{
      .yuerin-seven-carousel{
        background-image:url('../assets/images/bg2.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-seven-top{
          margin-top: 14px;
          span{
            font-weight: 300;
            display: block;
          }
          .yuerin-seven-top-ti{
            font-size: 24px;
            margin-bottom: 14px;
          }
          .yuerin-seven-top-tx{
            font-size: 14px;
            color: #4e4e4e;
          }
        }
        .yuerin-seven-bottom{
          width: 90%;
          margin: 0 auto;
          margin-top: 10px;
          .yuerin-seven-card-2{
            text-align: left;
            min-height: 170px;
            max-height: 170px;
            border-radius: 15px 0px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 16px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 12px;
              }
            }
          }
          .yuerin-seven-card{
            text-align: left;
            border-radius: 0px 15px;
            min-height: 170px;
            max-height: 170px;
            .yuerin-seven-card-ti{
              margin-bottom: 2px;
              span{
                color: #ea5514;
                font-size: 16px;
              }
            }
            .yuerin-seven-card-tx{
              span{
                color: #4e4e4e;
                font-size: 12px;
              }
            }
          }
        }
        
      }
    }
    .yuerin-eight{
      .yuerin-eight-carousel{
        background-image:url('../assets/images/bg3.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .yuerin-eight-top{
          margin-top: 40px;
          span{
            font-size: 24px;
            font-weight: 300;
            color: #fff;
          }
        }
        .yuerin-eight-bottom{
          width: 90%;
          margin:  0 auto;
          margin-top: 30px;
          .yuerin-eight-bottom-boxs{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            span{
              font-weight: 100;
              color: #fff;
              font-size: 12px;
            }
            .yuerin-eight-bottom-boxs-ti{
              font-size: 35px;
            }
            .yuerin-eight-bottom-boxs-tx{
              font-size: 16px;
            }
          }
        }
      }
    }
    .yuerin-nine{
      padding-top: 100px;
      padding-bottom: 100px;
      .yuerin-nine-top{
        span{
          display: block;
          font-weight: 300;
        }
        .yuerin-nine-top-ti{
          font-size: 24px;
          margin-bottom: 14px;
        }
        .yuerin-nine-top-tx{
          font-size: 14px;
          color: #4e4e4e;
        }
      }
      .yuerin-nine-bottom{
        width: 90%;
        margin: 0 auto;
        .yuerin-nine-bottom-card{
          border-radius: 15px;
          margin-top: 43px;
          text-align: left;
          background-color: #ea5514;
          min-height: 166px;
          padding-left: 18px;
          .yuerin-nine-bottom-card-top{
            margin-top: 10px;
            .yuerin-nine-bottom-card-top-left{
              display: inline-block;
              vertical-align: top;
              width: 100%;
              span{
                font-size: 16px;
                color: #fff;
                font-weight: 100;
                line-height: 50px;
              }
            }
            .yuerin-nine-bottom-card-top-right{
              display: inline-block;
              width: 80px;
              vertical-align: top;
              display: none;
              i{
                font-size: 40px;
                color: #fff;
                opacity: 0.3;
                line-height: 50px;
                
              }
            }
          }
          .yuerin-nine-bottom-card-bottom{
            span{
              font-weight: 100;
              color: #fff;
              display: block;
            }
            .yuerin-nine-bottom-card-bottom-ti{
              font-size: 12px;
              margin-bottom: 10px;
              margin-right: 15px;
            }
            .yuerin-nine-bottom-card-bottom-tx{
              font-size: 12px;
            }
          }
        }
      }
    }
    .yuerin-ten{
      padding-bottom: 20px;
      background-color: #242424;
      height: auto;
      padding-top: 40px;
      .yuerin-ten-box{
        text-align: left;
        width: 90%;
        margin: 0 auto;
      .yuerin-ten-boxs{
        margin-bottom: 15px;
        display: block;
        vertical-align: top;
        width: 90%;
        padding: 0 23px 0px 23px;
        border-right:0px solid #A3A3A3;
        .yuerin-ten-boxs-logos{
            .yuerin-ten-boxs-lo1{
              text-align: center;
              background-color: #ea5514;
              width: 42px;
              height: 42px;
              display: inline-block;
              vertical-align: top;
              border-radius: 5px;
              i{
                line-height: 42px;
                color: #fff;
                font-size: 30px;
              }
            }
            .yuerin-ten-boxs-lo2{
              display: inline-block;
              vertical-align: top;
              i{
                margin-left: 15px;
                line-height: 42px;
                color: #ea5514;
                font-size: 30px;
              }
            }
        }
        .yuerin-ten-boxs-ti{
          color: #fff;
        }
        span{
          display: block;
          font-size: 13px;
          color: #A3A3A3;
          margin-bottom: 3px;
          cursor: pointer;
          i{
            font-size: 13px;
          }
        }
      } 
      .yuerin-ten-boxs-one{
          width: 300px;
          border-right:0px solid #A3A3A3;
          .yuerin-ten-boxs-logos-bo{
            margin-top: 12px;
            span{
              color: #A3A3A3;
              font-size: 13px;
            }
        }
      }
      .yuerin-ten-boxs-two{
        width: 90%;
      }
      }
      .yuerin-ten-over{
        color: #A3A3A3;
        font-size: 13px;
        a{
          color: #A3A3A3;
        }
      }
    }
  }
}
</style>
