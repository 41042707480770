const en = {
    zh:'中文',
    add:'Room 301B, Environmental Monitoring Building, District 71,Xingdong Community,Xinan Street,Baoan District,Shenzhen',
    en:'English',
    title: "title",
    contactUs:"contact us",
    more:"more",
    manager:"Product Manager",
    executive:"executive director",
    Major:"Major Customer Manager",
    product:'product',
    program:'program',
    aboutUs:'about us',
    solutions:'Expert in intelligent hardware solutions!',
    technologies:"core",
    startNow:'Smart Meetings, Start Now',
    lightweight :'Lightweight all-in-one smart conference terminal, with a powerful smart microphone to make your conference reachable with one click',
    videoTerminal:'Video Terminal A6',
    stereoMicrophone:'Microphone M1',
    remoteControl:'Remote Control',
    painPoints:'Instant customization to solve pain points',
    authoritative:'Authoritative experts in the field of audio and video customize the most suitable solution for you according to your needs',
    fullyCompatible:'Fully compatible',
    compatibility:'Supports comprehensive and reliable third-party application compatibility',
    customization:'Flexible customization',
    support:'Support multi-industry, multi-field flexible configuration, VIP customized service',
    reliable:'Safe and reliable',
    choice:'15 years of audio and video technology accumulation, safe and reliable, is your best choice',
    maturity:'ecological maturity',
    chip:'Mature chip ecosystem, safe and reliable supply chain',
    sales:'No worries after sale',
    solve:"Customer-centric, one-stop pre-sales and after-sales service, to solve your problems",
    winwin:'win-win',
    cooperation:'Flexible form of cooperation, become partners and move forward hand in hand, win-win cooperation',
    aboutYuerin:'About Yuerin',
    smartly:'Empower the smart IoT ecosystem and build a work and life that people enjoy smartly!',
    yuertx:"Yueer Innovation is an open, trustworthy, efficient and innovative partner team. The company's vision is to build a team of partners who work hard and work hard to jointly create high-quality Yueer. Er brand!With AV IoT intelligent technology and intelligent operating system technology as the core, the company focuses on the commercialization of AV, IoT, artificial intelligence, communication and other cutting-edge technologies, provides innovative technologies for the intelligent IoT industry, and builds an intelligent IoT ecosystem.",
    trust:'Trust, create value together',
    together:'Work together to create value and build the future together',
    trustz:'trust',
    yuerin:'yuerin',
    attention:'Pay attention to the trust between customers, partners and teams. Advocating the spirit of service, cultivating service awareness, serving customers, partners and teams, making their work and life happier and happier!',
    focus:'Focus',
    extreme:'extreme',
    solution1:'The road is simple, focusing on providing customers with the ultimate technical solution!',
    publicPraise:'public praise',
    fast:'fast',
    brand:'Ensure security and confidentiality, quickly provide customers with high quality technology and service, far exceed customer expectations, build yue er brand.',
    open:'open',
    ecological:'ecological',
    purpose:'With the purpose of openness, cooperation and inclusiveness, we will create open technology platforms and products, maintain ecological cooperation between customers and partners, and achieve win-win cooperation!',
    philosophy:'Service philosophy',
    Professional:"Professional team worth your trust",
    profession:'profession',
    professionalteam:"Huawei's team has insisted on making audio and video intelligent hardware for 15 years.From product demand analysis,specification definition,R&D and production,the whole chain is professional",
    trust2:'trust',
    direction:'Being integrated, strategically empowering customers, not making business plans for customers, and not robbing customers of their business direction',
    focus2:'focus',
    AVIoT:'Focus on video and audio Internet of Things (AVIoT) intelligent hardware products',
    open2:'open',
    rewards:'With an open and cooperative attitude, work with customers and partners to do good things and reap rewards',
    reputation:'Strength creates reputation',
    year:'year',
    kind:'kind',
    indivual:'indivual',
    accumulation:'Video conferencing technology accumulation',
    security:'security',
    scene:'service scene',
    solution:'mature solution',
    call:'Start a different video call',
    performance:"Compatible with excellent applications on many platforms, service acceleration, enhanced user experience, high speed and stability, high cost performance",
    sale:'Pre-sale and after-sale',
    immediately:'Have any questions about the product? Please contact our pre-sales consultation lady, we will answer you immediately.',
    Technical:'Technical Support',
    questions:'Have any questions about the product? Please contact our technical experts to answer your questions right away.',
    Empowering:'Empowering the intelligent IoT ecosystem to build a work and life that people enjoy smartly!',
    entry:'quick entry',
    description:'product',
    solution:'solution',
    service:'service',
    help:'help',
    address:'address',
    phone:"phone",
    email:'email'
}
export default en;