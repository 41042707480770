<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>
<script>
  import { provideI18n } from "./uilt/i18n";
  import en from "./uilt/langs/en";
  import cn from "./uilt/langs/cn";

  export default {
    setup() {
      provideI18n({
        locale: "cn",
        messages: {
          en: en,
          cn: cn
        }
      });
    }
  };
</script>
<style lang="scss">
html{
  height: 100%;
}
body{
  margin: 0;
  height: 100%;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
