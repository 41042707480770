import "babel-polyfill"
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueParticles from 'vue-particles'  
import promise from 'es6-promise'
promise.polyfill()
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import './assets/icon/iconfont.css'
import * as Icons from "@element-plus/icons-vue"; //导入所有element icon图标
// 注册全局图标

const app = createApp(App)

Object.keys(Icons).forEach((key) => {
    app.component(key, Icons[key]);//JS中用这行
// app.component(key, Icons[key as keyof typeof Icons]);//TS中用这行
});
app.use(ElementPlus).use(VueParticles)
app.use(store).use(router).mount('#app')
