const cn = {
    zh:'中文',
    en:'English',
    add:'深圳市宝安区新安街道兴东社区71区环境监测大厦301B',
    title: "标题",
    contactUs:"联系我们",
    manager:"产品经理",
    Major:"大客户经理",
    executive:"执行董事",
    more:"更多",
    product:'产品展示',
    program:'定制解决方案',
    aboutUs:'关于我们',
    solutions:'智能硬件解决方案专家！',
    technologies:"核心技术",
    startNow:'智能会议，即刻开始',
    lightweight :'轻量化一体式智能会议终端，搭配强大智能麦克让您的会议一键即达',
    videoTerminal:'视频会议终端A6',
    stereoMicrophone:'立体麦克风M1',
    remoteControl:'会议遥控器',
    painPoints:'即刻定制，解决痛点',
    authoritative:'音视频领域权威专家根据您的需求为您量身定制最合适的解决方案',
    fullyCompatible:'全面兼容',
    compatibility:'支持全面且可靠的第三方应用程序兼容',
    customization:'灵活定制',
    support:'支持多行业、多领域灵活配置，VIP定制服务',
    reliable:'安全可靠',
    choice:'15年音视频技术积累，安全可靠，是您的最优选择',
    maturity:'生态成熟',
    chip:'成熟的芯片生态体系，供应链安全可靠',
    sales:'售后无忧',
    solve:"以客户为中心，一站式售前售后服务，为您排忧解难",
    winwin:'合作共赢',
    cooperation:'灵活的合作形式，成为伙伴携手前行合作共赢',
    aboutYuerin:'关于悦尔',
    smartly:'赋能智能物联网生态，构建人们智能享受的工作和生活！',
    yuertx:"悦尔创新是一个开放、信任、高效、创新的合伙人团队，公司的愿景是创建“力出一孔、利出一孔”，自发努力、自发奋斗的合伙人团队，携手合作打造优质的悦尔品牌！",
    yuertx2:"公司以视音频物联网智能技术和智能操作系统技术为核心，聚焦视音频、物联网、人工智能、通讯等前沿技术，助力并加速智能软件、智能物联网、智能服务等行业领域的产品化与商业化，为智能物联网产业提供创新技术，构建智能物联网生态体系。",
    trust:'信任，共创价值',
    together:'携手共创价值，齐心共筑未来',
    trustz:'信任',
    yuerin:'悦尔',
    attention:'重视客户、伙伴、团队间的信任。倡导服务精神，培养服务意识，为客户、伙伴、团队服务，使其工作和生活更快乐、更幸福！',
    focus:'专注',
    extreme:'极致',
    solution1:'大道至简，专注于为客户提供极致的技术方案！',
    publicPraise:'口碑',
    fast:'快',
    brand:'确保安全和保密，快速地为客户提供优质的技术和服务，远超客户预期，打造悦尔品牌。',
    open:'开放',
    ecological:'生态',
    purpose:'以开放、合作、包容为宗旨，打造开放性技术平台和产品，保持客户与合作伙伴间的生态合作，合作共赢！',
    philosophy:'服务理念',
    professional:"专业团队值得您的信任",
    profession:'专业',
    professionalteam:'华为终端团队，15年如一日坚持做音视频物联网智能硬件。从产品需求分析、规格定义、研发生产、供应链交付，全链条专业',
    trust2:'信任',
    direction:"被集成，战略赋能客户，不做专为客户提供的业务方案，不抢夺客户的业务方向",
    focus2:'专注',
    AVIoT:'专注于视音频物联网（AVIoT）智能硬件类产品',
    open2:'开放',
    rewards:'以开放合作的心态，和客户、合作伙伴一起，做好事情，收获回报',
    reputation:'实力铸就口碑',
    year:'年',
    kind:'种',
    indivual:'个',
    accumulation:'视频会议技术积累',
    security:'安全保障',
    scene:'服务场景',
    solution:'成熟解决方案',
    call:'开启不一样的视频通话',
    performance:'与诸多平台上的优秀应用兼容，服务加速，增强用户体验，高速稳定的高性价',
    sale:'售前售后',
    immediately:'有任何关于产品的疑问？请联系我们的售前咨询小姐姐，马上为您解答。',
    Technical:'技术支持',
    questions:'有任何关于产品的疑问？请联系我们的技术大佬，马上为您解答。',
    Empowering:'赋能智能物联生态构筑人们悦尔智享的工作与生活！',
    entry:'快捷入口',
    description:'产品介绍',
    solution:'解决方案',
    service:'服务支持',
    help:'帮助中心',
    address:'地址',
    phone:"电话",
    email:'邮箱'
}
export default cn;